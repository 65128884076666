import { api } from '../utils/api';


/** 获取用户信息  */
export async function getUserInfo(data) {
    return api('/web/user/info', data, 'POST', true, true, false);
}

// 我的 修改密码
export async function updatePassword(data) {
    return api('/web/user/pass-update', data, 'POST', true);
}

/** 获取用户厂商经验  */
export async function getCompanyExperience(data) {
    return api('/web/task/value/user', data, 'POST', true);
}
// 修改用户昵称
export async function updateNickName(data) {
    return api('/web/user/update', data, 'POST', true);
}

// 修改用户头像
export async function updateAvatar(data) {
    return api('/web/user/update', data, 'POST', true);
}

// 获取公众号openid
export async function getMpOpenId(data) {
    return api('/web/ec/pay/mp-open-id', data, 'POST', false, false, false);
}
