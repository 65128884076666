/**
 * 常量
 */

// export const webUrl = 'http://127.0.0.1:8080';
// export const webUrl = 'http://test-mall.helphn.com'; // 网站地址
// export const webUrl = 'http://mall.helphn.com'; // 网站地址
export const webUrl = process.env.VUE_APP_WEB_URL; // 网站地址
export const appId = 'wx8fd391bfcf664ac8'; // 微信公众号appId
export const version = '1.0';
export const defaultGuid = 'zxcvbnmqw987';
export const platform = 'client';
export const defaultToken = 'jfsc202308';
export const hashRule = [
  [9, 18, 19, 23, 3, 31],
  [1, 4, 12, 17, 22, 29],
  [6, 19, 31, 0, 12, 18],
  [4, 22, 21, 11, 5, 10],
  [8, 20, 12, 28, 25, 20],
  [12, 28, 13, 19, 20, 21],
  [10, 24, 16, 27, 11, 22],
  [14, 10, 28, 18, 16, 12],
];

export const apiWhiteList = [
  '/web/user/register',
  '/web/user/find-pass',
  '/web/user/login',
  '/web/sms/code',
  '/web/oss/get/sign',
];

// 题目类型
export const questionType = {
  1: '单选题',
  2: '多选题',
  3: '判断题',
};

// 订单操作平台
export const platformList = {
  client: '客户端',
  admin: '平台',
  store: '商家',
};

// 订单生成类型
export const orderType = {
  1: '普通订单',
  2: '子订单',
  3: '兑换订单',
};

// 订单状态
export const orderStatus = {
  1: {
    statusText: '待付款',
    isCancel: true,
    handle: ['continuePayment'],
    detailHandle: 'continuePayment',
  },
  30: {
    statusText: '订单待确认',
    isCancel: false,
    handle: [],
  },
  31: {
    statusText: '待发货',
    isCancel: false,
    handle: [],
  },
  32: {
    statusText: '待收货',
    isCancel: false,
    handle: ['addToCart', 'confirmReceipt'],
    detailHandle: 'confirmReceipt',
  },
  4: {
    statusText: '已完成',
    isCancel: false,
    handle: ['delete', 'addToCart'],
    detailHandle: 'deleteOrder',
  },
  5: {
    statusText: '交易关闭',
    isCancel: false,
    handle: ['delete', 'addToCart'],
    detailHandle: 'deleteOrder',
  },
  6: {
    statusText: '待退款',
    isCancel: false,
    handle: [],
    detailHandle: 'confirmReceipt',
  },
  7: {
    statusText: '退款中',
    isCancel: false,
    handle: [],
    detailHandle: 'confirmReceipt',
  },
  8: {
    statusText: '交易关闭',
    isCancel: false,
    handle: ['delete', 'addToCart'],
  },
  9: {
    statusText: '交易关闭',
    isCancel: false,
    handle: ['delete', 'addToCart'],
  },
  21: {
    text: '处理中',
    status: 'Processing',
    handle: ['addToCart', 'cancelApply'],
  },
  22: {
    text: '处理中',
    status: 'Processing',
    handle: ['addToCart', 'viewDetail'],
  },
  // 24: {
  //     text: '',
  //     status: 'Processing',
  //     handle: ['delete', 'addToCart', 'viewDetail'],
  // },
  28: {
    text: '完成',
    status: 'Processing',
    handle: ['delete', 'addToCart', 'viewDetail'],
  },
  20: {
    text: '完成',
    status: 'Processing',
    handle: ['delete', 'addToCart', 'viewDetail'],
  },
  29: {
    text: '售后取消',
    status: 'Processing',
    handle: ['delete', 'addToCart', 'viewDetail'],
  },
};
// 订单详情的产品状态
export const orderDetailProdStatus = {
  1: {
    statusText: '待付款',
    handle: ['addToCart'],
  },
  30: {
    statusText: '订单待确认',
    handle: ['addToCart', 'refund'],
  },
  31: {
    statusText: '待发货',
    handle: ['addToCart', 'refund'],
  },
  32: {
    statusText: '待收货',
    handle: ['addToCart', 'refund'],
  },
  4: {
    statusText: '已完成',
    handle: ['addToCart', 'refund'],
  },
  5: {
    statusText: '已取消',
    handle: ['addToCart'],
  },
  6: {
    statusText: '待退款',
    handle: ['addToCart'],
  },
  7: {
    statusText: '退款中',
    handle: ['addToCart'],
  },
  8: {
    statusText: '退款成功',
    handle: ['addToCart', 'serviceInfo'],
  },
  9: {
    statusText: '交易关闭',
    handle: ['addToCart', 'delete', 'viewDetail'],
  },
  21: {
    statusText: '退款中',
    handle: ['addToCart', 'serviceInfo'],
  },
  22: {
    statusText: '同意售后',
    handle: ['addToCart', 'serviceInfo'],
  },
};
// 订单申请状态
export const orderServiceStatus = {
  21: {
    text: '售后申请中',
    status: 'Processing',
    handle: ['agree', 'refuse'],
  },
  22: {
    text: '同意售后',
    status: 'Success',
    handle: [],
  },
  23: {
    text: '拒绝售后',
    status: 'Error',
    handle: [],
  },
  24: {
    text: '申请撤销',
    status: 'Default',
    handle: [],
  },
  25: {
    text: '用户已退货',
    status: 'Default',
    handle: ['confirmReceipt'],
  },
  26: {
    text: '商家已收到退货',
    status: 'Default',
    handle: ['reship'],
  },
  27: {
    text: '商家已重新邮寄',
    status: 'Default',
    handle: [],
  },
  28: {
    text: '换货成功',
    status: 'Default',
    handle: [],
  },
};

// 订单可操作
export const orderHandle = {
  //zxb~~~~~~~~~~~~~~~~
  continuePayment: { title: '继续支付' },
  //   confirmReceipt: { title: '确认收货' },
  addToCart: { title: '加购物车', class: 'gray' },
  applyService: { title: '申请售后' },
  refund: { title: '退换' },
  delete: { title: '删除记录', class: 'gray' },
  serviceInfo: { title: '售后详情', class: 'gray' },
  viewDetail: { title: '查看详情' },
  cancelApply: { title: '撤销申请' },
  //zxb~~~~~~~~~~~~~~~~
  cancel: { title: '取消订单', class: 'gray', tip: '取消订单后, 订单将关闭' },
  payment: { title: '线下收款', class: '', tip: false },
  send: { title: '发货', class: '', tip: '' },
  confirm: { title: '确认', class: '', tip: '' },
  write_off: { title: '核销', class: '', tip: '' },

  // "see_logistics": { title: "查看物流", class: 'white', tip: '' },

  // 退款
  agree: { title: '同意售后', class: '', tip: '' },
  refuse: { title: '拒绝售后', class: '', tip: '' },

  // 售后
  progress: { title: '售后详情', class: '', tip: '' },
  confirmReceipt: { title: '确认收货', class: '', tip: '' },
  reship: { title: '重新发货', class: '', tip: '' },
};
// 订单详情 可操作
export const orderDetailHandle = {
  continuePayment: {
    title: '继续支付',
  },
  confirmReceipt: {
    title: '确认收货',
    class: 'confirm-receipt',
  },
  deleteOrder: {
    title: '删除订单',
    class: 'delete-order',
  },
};
// 订单详情  商品操作
export const orderDetailProdHandle = {
  addToCart: { title: '加购物车', class: 'gray' },
  applyService: { title: '申请售后' },
  serviceInfo: { title: '售后详情', class: 'gray' },
  viewDetail: { title: '查看详情' },
  refund: { title: '退换' },
};
// 售后类型
export const afterSalesType = {
  return_goods: {
    text: '退货退款',
    tooltips: '确认同意退货申请并退款?',
  },
  change_goods: {
    text: '换货',
    tooltips: '确认同意换货？',
  },
  refund: {
    text: '退款',
    tooltips: '确认退款申请并退款?',
  },
};

// 分类产品模版
export const productTemplate = [
  {
    label: '快递零售',
    value: 'entity_retail',
    status: { 31: '待发货', 32: '待收货' },
  },
  // { label: '酒店住宿', value: 'hotel_lodging', status: { 41: '待确认', 42: '已确认' } },
  { label: '各类门票', value: 'travel_ticket', status: { 51: '待出行' } },
  { label: '线下核销', value: 'catering', status: { 61: '待使用' } },
  { label: '剧本/道具/大礼包', value: 'props', status: { 71: '待发放' } },
];

// 账户类型
export const accountType = {
  1: {
    text: '个人',
  },
  2: {
    text: '企业',
  },
};

// 账户状态
export const accountStatus = {
  1: {
    text: '正常',
    status: 'Success',
  },
  2: {
    text: '冻结',
    status: 'Error',
  },
};

// 账户种类
export const accountCode = {
  integral: {
    text: '积分', // 积分
    scaling: 10, // 积分换算
    cash_out: 0, // 是否可提现
  },
};

// 支付方式
export const pay_way = {
  ...accountCode,
  wx: {
    text: '微信',
    scaling: 1,
  },
  zfb: {
    text: '支付宝',
    scaling: 1,
  },
};

// 账户记录类型
export const accountLogType = {
  1: {
    text: '余额增加',
    tip: '+ ',
  },
  2: {
    text: '余额扣减',
    tip: '- ',
  },
  3: {
    text: '余额回退',
    tip: '+ ',
  },
  4: {
    text: '余额冻结',
    tip: '- ',
  },
  5: {
    text: '冻结扣减',
    tip: '- ',
  },
  6: {
    text: '冻结转余额',
    tip: '+ ',
  },
};

// 账户记录类型
export const accountLogMode = {
  order_cancel: {
    text: '取消订单',
  },
  order_exchange: {
    text: '兑换商品',
  },
  pay: {
    text: '支付',
  },
  cash_out: {
    text: '提现',
  },
  recharge: {
    text: '充值',
  },
  admin_handle: {
    text: '后台充值',
  },
  transfer: {
    text: '用户转账',
  },
  task: {
    text: '游戏任务',
  },
  achieve: {
    text: '成就奖励',
  },
  spread: {
    text: '推广佣金',
  },
  order_income: {
    text: '订单收益',
  },
};

// 支付方式
export const payType = {
  1: {
    text: '线下支付',
  },
  2: {
    text: '微信交易号',
  },
  3: {
    text: '支付宝交易号',
  },
};

// 账户记录状态
export const accountLogStatus = {
  1: {
    text: '完成',
    status: 'Success',
  },
  2: {
    text: '审核中',
    status: 'Processing',
  },
  3: {
    text: '审核失败',
    status: 'Error',
  },
  4: {
    text: '审核成功',
    status: 'Success',
  },
  5: {
    text: '转账中',
    status: 'Processing',
  },
  6: {
    text: '转账失败',
    status: 'Error',
  },
  7: {
    text: '待入账',
    status: 'Warning',
  },
  8: {
    text: '关闭',
    status: 'Default',
  },
};

// 企业等级
export const storeGrade = {
  1: {
    text: '普通',
  },
  2: {
    text: '严选',
  },
  3: {
    text: '官方',
  },
};

// 企业状态
export const storeStatus = {
  1: {
    text: '正常',
    status: 'Success',
  },
  2: {
    text: '关闭',
    status: 'Error',
  },
  // 3: {
  // 	text: '审核中',
  // 	status: 'Error',
  // },
  // 4: {
  // 	text: '审核成功',
  // 	status: 'Error',
  // },
  // 5: {
  // 	text: '审核失败',
  // 	status: 'Error',
  // },
  // 8: {
  // 	text: '申请中',
  // 	status: 'Error',
  // },
};

// 企业考核状态
export const storeExamineStatus = {
  1: {
    text: '未考核',
    status: 'Default',
  },
  2: {
    text: '考核通过',
    status: 'Success',
  },
  3: {
    text: '考核中',
    status: 'Processing',
  },
  4: {
    text: '考核未通过',
    status: 'Error',
  },
};

// 企业成员类型
export const storeUserType = {
  1: {
    text: '拥有者',
  },
  2: {
    text: '成员',
  },
};

// 产品状态
export const productStatus = {
  1: {
    text: '已上架',
    status: 'Success',
  },
  2: {
    text: '已下架',
    status: 'Error',
  },
  3: {
    text: '草稿',
    status: 'Processing',
  },
  4: {
    text: '定时上架',
    status: 'Success',
  },
  5: {
    text: '审核中',
    status: 'Warning',
  },
  6: {
    text: '审核通过',
    status: 'Success',
  },
  7: {
    text: '审核不通过',
    status: 'Error',
  },
  // 8: {
  //   text: '草稿',
  //   status: 'Default',
  // },
};

export const cacheDomain = '.hnyclaw.com';

export const logisticsStatus = {
  0: '运单不存在或者未揽收',
  1: '已揽件',
  2: '运输中',
  3: '派件中',
  4: '已签收',
  5: '异常',
  6: '代签收',
};

//经营范围
export const manageScope = [
  { value: 'food', label: '食品经营' },
  { value: 'hotel', label: '酒店住宿' },
  { value: 'entertainment', label: '娱乐场所' },
  { value: 'bath', label: '洗浴服务' },
  { value: 'retail', label: '零售商贸' },
  { value: 'other', label: '其他' },
];

// 状态
export const commonStatus = {
  1: {
    text: '已上架',
    status: 'Success',
  },
  2: {
    text: '已下架',
    status: 'Error',
  },
};

// 任务类型
export const taskType = {
  1: {
    text: '普通任务',
  },
  2: {
    text: '重复任务',
  },
  3: {
    text: '每日任务',
  },
};

// 任务方式
export const taskMethod = {
  sign_in: {
    text: '签到',
    status: 'Processing',
  },
  answer: {
    text: '答题',
    status: 'Processing',
  },
  npc: {
    text: 'NPC',
    status: 'Processing',
  },
};
// 用户任务状态
export const userTaskStatus = {
  1: {
    text: '已完成',
  },
  2: {
    text: '已领取',
  },
  6: {
    text: '待NPC确认',
  },
  // '3': {
  //     text: '已失败',
  //     status: 'Processing',
  // },
  // '4': {
  //     text: '已过期',
  //     status: 'Processing',
  // },
  // '5': {
  //     text: '已放弃',
  //     status: 'Processing',
  // },
};
// 数值类型
export const valueType = {
  experience: {
    text: '经验',
    status: 'Processing',
  },
  reputation: {
    text: '声望',
    status: 'Processing',
  },
};

// 标签类型
export const tagType = {
  tag: {
    text: '普通标签',
    status: 'Processing',
  },
  system: {
    text: '系统标签',
    status: 'Processing',
  },
  custom: {
    text: '自定义标签',
    status: 'Processing',
  },
  recommend: {
    text: '推荐位标签',
    status: 'Processing',
  },
};

// 内容类型
export const contentType = {
  post: {
    text: '文章',
    status: 'Processing',
  },
  poster: {
    text: '广告',
    status: 'Processing',
  },
  banner: {
    text: '轮播图',
    status: 'Processing',
  },
};

// 内容状态
export const contentStatus = {
  1: {
    text: '已发布',
    status: 'Success',
  },
  2: {
    text: '待发布',
    status: 'Warning',
  },
  4: {
    text: '定时发布',
    status: 'Success',
  },
  5: {
    text: '审核中',
    status: 'Warning',
  },
  6: {
    text: '审核通过',
    status: 'Success',
  },
  7: {
    text: '审核不通过',
    status: 'Error',
  },
  8: {
    text: '草稿',
    status: 'Default',
  },
};

// 卡券类型
export const couponType = {
  1: '商品券',
  5: '任务专属券',
};
// 卡券状态
export const couponStatus = {
  1: '未使用',
  2: '已使用',
  3: '已过期',
};

// 日志类型
export const logType = {
  1: '普通记录',
  3: '售后',
};

// 中间页
export const midPage = {
  answer: -2, // 答题
  'serve/refundGood': -3, // 退货退款
  'serve/refund': -3, // 仅退款
  'serve/exchangeGood': -3, // 换货
  'order/confirm': -2, // 订单确认 确认支付
  'order/complate': -3, // 订单完成 支付成功
  'order/exchange': -2, // 卡券兑换 确认兑换
};
