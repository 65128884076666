/**
 * @file Proxy代理重写对象getter、setter、delete，使用Storage持久化存储
 * @author yujian wenfugui97@gmail.com
 * @date 2022.04.25
 */

/**
 * @description 使用Storage存储数据
 * @class Storage
 */
class Storage {
  static setItem(key, value) {
    try {
      const val = JSON.stringify(value);
      localStorage.setItem(key, val);
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  static getItem(key) {
    try {
      const val = localStorage.getItem(key);
      return val ? JSON.parse(val) : null;
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  static removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing data from localStorage', e);
    }
  }
}

export const createPersistentObject = (
  target,
  key = 'persistent_object',
  watcher = () => {}
) => {
  const saved = Storage.getItem(key);
  if (saved) {
    // 拷贝
    Object.assign(target, saved);
  }
  return new Proxy(target, {
    get: (obj, prop) => {
      return Reflect.get(obj, prop);
    },

    set: (obj, prop, value) => {
      if (Reflect.get(obj, prop) !== value) {
        Reflect.set(obj, prop, value);

        watcher(prop, value);

        Storage.setItem(key, obj);
      }

      return true;
    },

    deleteProperty: (obj, prop) => {
      Reflect.deleteProperty(obj, prop);
      Storage.setItem(key, obj);
      return true;
    },
  });
};
