/**
 * 获取url参数
 * queryName 参数名
 */
export function getQueryVariable(queryName) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === queryName) {
            return pair[1];
        }
    }
    return false;
}

// 判断是移动端浏览器还是 PC 端浏览器
export function is_mobile() {
    const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    return reg.test(navigator.userAgent);
}

// 判断是 iPhone 还是 Android
export function is_iPhone() {
    const reg = /iPhone/i;

    return reg.test(navigator.userAgent);
}

//图片转换为base64
export function getImgToBase64(url, callback) {
    let canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}

/*
 * 函数节流
 */
export function Throttle(fn, time) {
    let last;
    const interval = time || 200;
    return function () {
        const th = this;
        const args = arguments;
        const now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, interval);
        } else {
            last = now;
            fn.apply(th, args);
        }
    };
}

/**
 * 获取路由参数
 * return String
 */
export function getHrefQuery() {
    var query = window.location.search;
    return query;
}

/**
 * 路由参数字符串转对象
 * return Object
 */
export function queryStringToObj() {
    var query = window.location.search;
    query = query.substring(1);
    query = query.split('&');
    let tarObj = {};
    for (let i = 0; i < query.length; i++) {
        if (query[i].indexOf('=') == -1) continue;
        let key = query[i].split('=')[0];
        let value = query[i].split('=')[1];
        tarObj[key] = value;
    }
    return tarObj;
}

/**
 * 获取路由参数指定键对应值
 * @param param 查询的参数键
 * return Object
 */
export function getSearchQuery(param, type) {
    var search = window.location.search;
    search = search.substring(1, search.length);
    var targetArr = search.split('&');
    var targetObj = {};
    for (let i = 0; i < targetArr.length; i++) {
        var key = targetArr[i].split('=')[0];
        var value = targetArr[i].split('=')[1];
        targetObj[key] = value;
    }

    if (param in targetObj) {
        let obj = {};
        if (type == 'int' || type == 'Int') {
            obj[param] = parseInt(targetObj[param]);
        } else {
            obj[param] = targetObj[param];
        }
        return obj;
    }
    return targetObj;
}

/**
 * 对象转uri参数字符串
 * @param query (参数对象)
 * @return String
 */
export function objToQueryString(query) {
    let queryStr = '?';
    for (let key in query) {
        queryStr += key + '=' + query[key] + '&';
    }
    let length = queryStr.length - 1;
    let target = queryStr.substr(0, length);
    return target;
}

// 生成随机字符
export const getUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
