import { router } from '../router';
import axios from 'axios';
import md5 from 'js-md5';
import {
  closeToast,
  showConfirmDialog,
  showLoadingToast,
  showFailToast,
} from 'vant';
import {
  version,
  defaultGuid,
  platform,
  defaultToken,
  hashRule,
} from '../constant/constant';
import { userInfo } from './globalData';

// 创建 axios 请求实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  method: 'post',
  headers: { 'content-type': 'application/json' },
  timeout: 10000, // 请求超时设置
});

/**
 * 请求
 * @param path        接口名称
 * @param parameter   参数
 * @param methods     请求方式
 * @param isBusiness  是否必须登录
 * @param isSync      是否同步
 * @param loading     是否显示加载状态
 */
export function api(
  path,
  parameter,
  methods = 'POST',
  isBusiness = false,
  isSync = true,
  loading = true
) {
  const userGuid = userInfo.guid;
  const userToken = userInfo.token;
  const time = Date.parse(new Date()) / 1000;
  let tempToken = '';
  let str = '';
  let signatures = '';
  let temp = '';

  // 接口是否需要登录
  if (isBusiness && !userToken) {
    showConfirmDialog({
      title: '请先登录',
    })
      .then(() => {
        return router.push({ path: '/login' });
      })
      .catch(() => {});
    return Promise.reject('请先登录');
  }

  // 是否显示加载状态
  if (loading) {
    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
    });
  }

  if (isBusiness) {
    temp += userToken.substr(5, 1);
    temp += userToken.substr(8, 1);
    temp += userToken.substr(7, 1);

    const num = parseInt(temp, 16) % 8;
    const temp_hash = hashRule[num];

    for (let i = 0; i < 6; i++) {
      tempToken += userToken.substr(temp_hash[i], 1);
    }

    str = time + path + userGuid + tempToken;
  } else {
    str = time + path + defaultGuid + defaultToken;
  }

  // 生成签名
  signatures = md5(str);

  // 发出请求
  return request(path, {
    method: methods,
    data: parameter, // 直接用原始参数
    responseType: 'json', // default
    headers: {
      'Ghwl-Time': time,
      'Ghwl-User': userGuid || defaultGuid,
      'Ghwl-Version': version,
      'Ghwl-Signatures': signatures,
      'Ghwl-Platform': platform,
    },
  }).then((res) => {
    const { code, data, msg } = res.data;
    // 错误处理
    if (code !== 20000) {
      switch (code) {
        case '40002':
        case '40004':
        case '40005':
        case '40007':
        case '40009':
          localStorage.removeItem('userInfo');
          router.push({ path: '/login' });
          break;
        default:
          if (loading) showFailToast(msg || data || '操作失败');
      }

      return Promise.reject(res.data);
    }

    // 成功区间
    closeToast();
    return Promise.resolve(res.data.data);
  });
}
