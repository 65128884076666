import { appId, webUrl } from '@/constant/constant';
import { getQueryVariable } from '@/utils/common';
import { getMpOpenId } from '@/api/user';
// import wx from 'weixin-js-sdk';

const wxOpenId = 'wx_open_id';

// 微信h5支付响应 表单提交
// export function wechatWapPay(content, redirect_url) {
//     const div = document.createElement('div');
//     div.innerHTML = decodeURI(content); // 表单字符串
//     let href = div.getElementsByTagName('a')[0].href;

//     //   if (!redirect_url && redirect_url == undefined) {
//     //     redirect_url =
//     //       process.env.NODE_ENV === 'production'
//     //         ? 'https://wap-ac.csdn.net'
//     //         : 'https://exam-mock.csdn.net';
//     const path = 'https://mall.helphn.com/order/payComplate'
//     // const path = 'https://mall.helphn.com/'
//     href = href + '&redirect_url=' + encodeURIComponent(path);
//     // href = href + '&redirect_url=' + path;
//     //   }
//     const form = document.createElement('form');
//     form.className = 'wechatpay';
//     form.method = 'post';
//     form.action = href;
//     document.body.appendChild(form);
//     document.getElementsByClassName('wechatpay')[0].submit();
// }

// 微信h5支付响应  replace
export function wechatWapPay(content) {
  const div = document.createElement('div');

  div.innerHTML = decodeURI(content); // 表单字符串

  let href = div.getElementsByTagName('a')[0].href;

  const path = 'https://mall.helphn.com/order/payComplate';

  href = href + '&redirect_url=' + encodeURIComponent(path);

  window.location.replace(href);
}
// 微信公众号支付响应
export function inWechatPay(params, success, fail) {
  const { appId, nonceStr, paySign, signType, timeStamp } = params;

  // 微信浏览器内置支付函数 其他浏览器无效
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest',
    {
      appId, //公众号名称
      nonceStr, //随机串
      package: params.package,
      paySign, //微信签名
      signType, //微信签名方式：
      timeStamp, //时间戳
    },
    function (res) {
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        success();
      } else {
        fail();
      }
    },
    success,
    fail
  );
}

// 是否微信浏览器
export function is_weixn() {
  // const reg = /MicroMessenger/i
  // return reg.test(navigator.userAgent)
  let ua = window.navigator.userAgent.toLowerCase(); // 不加window部分Android机会显示不了提示图，即判断不了是否是微信
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

// 检测是否小程序浏览器
export async function isWxMiniprogram(back = undefined) {
  var mini = false;

  let ua = window.navigator.userAgent.toLowerCase(); // 不加window部分Android机会显示不了提示图，即判断不了是否是微信

  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    await wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        mini = res.miniprogram;

        back && back();
      }
    });
  }

  return mini;
}

// 获取微信mp_open_id
export async function getOpenId(route) {
  const checkWx = is_weixn();
  const openid = openIdGet();

  // 判断是否在微信浏览器中 并且 openid 不存在
  if (!openid && checkWx) {
    const wxCcode = getQueryVariable('code');
    const state = getQueryVariable('state');
    const path = route.fullPath;

    // 获取微信授权
    if (!wxCcode) {
      getWXAuthorize(path);
      return;
    }

    // 获取授成功返回的code
    if (wxCcode && state) {
      // 使用code换取openid 存入store
      // store.dispatch('user/getWXOpenId', wxCcode).then(() => { });

      // 使用 code 换取 openid
      getMpOpenId({ code: wxCcode }).then((openid) => {
        openIdSet(openid);
      });
    }
  }
}

// 获取微信公众号用户授权
export function getWXAuthorize(path) {
  const url = 'https://open.weixin.qq.com/connect/oauth2/authorize';
  const app_id = appId;
  const redirect_uri = encodeURIComponent(`${webUrl}${path}`);
  const response_type = '&response_type=code';
  const scope = '&scope=snsapi_base';
  // const scope = '&scope=ssnsapi_userinfo'
  const state = '&state=type';
  const connect_redirect = '#wechat_redirect';

  const uri = `${url}?appid=${app_id}&redirect_uri=${redirect_uri}${response_type}${scope}${state}${connect_redirect}`;

  window.location.href = uri;
}

// 保存微信mp_open_id
export const openIdSet = (value) => {
  return localStorage.setItem(wxOpenId, value);
};

// 获取微信mp_open_id
export const openIdGet = () => {
  return localStorage.getItem(wxOpenId);
};

// 删除微信mp_open_id
export const openIdRemove = () => {
  return localStorage.removeItem(wxOpenId);
};
