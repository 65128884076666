import { createApp } from 'vue';
import './global_style.css';
import App from './App.vue';
import { router } from './router';
import { createPinia } from 'pinia';
import { Toast, ConfigProvider } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import '@/utils/amfe-flexible';

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
    const initialState = JSON.parse(JSON.stringify(store.$state));
    store.$reset = () => {
        store.$state = JSON.parse(JSON.stringify(initialState));
    };
});

app.use(router);
app.use(Toast);
app.use(ConfigProvider);
app.use(pinia);
app.mount('#app');
