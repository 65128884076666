import { createRouter, createWebHistory } from 'vue-router';
import { userInfo as localUserInfo } from './utils/globalData';
import { getOpenId } from './utils/wechat';
import { showConfirmDialog } from 'vant';

const routes = [
  {
    name: 'index',
    path: '/',
    component: () => import('./view/index'),
    meta: {
      goBack: true,
      goHome: true,
      title: '开心实验室创新实践平台',
    },
  },
  {
    name: 'debug',
    path: '/debug',
    component: () => import('./view/debug/index'),
    meta: {
      title: '开心实验室创新实践平台',
    },
  },
  {
    name: 'offerDescription',
    path: '/offerDescription',
    component: () => import('./view/index/offerDescription'),
    meta: {
      hideTabbar: true,
      title: '优惠说明',
    },
  },
  {
    name: 'reputation',
    path: '/reputation',
    component: () => import('./view/reputation'),
    meta: {
      hideTabbar: true,
      title: '品牌声望',
    },
  },
  {
    name: 'reputationDetail',
    path: '/reputationDetail',
    component: () => import('./view/reputation/reputationDetail'),
    meta: {
      hideTabbar: true,
      title: '声望详情',
    },
  },
  {
    name: 'myTask',
    path: '/myTask',
    component: () => import('./view/mine/task'),
    meta: {
      hideTabbar: true,
      title: '我的任务',
    },
  },
  {
    name: 'myTaskDetail',
    path: '/myTask/detail',
    component: () => import('./view/mine/task/detail'),
    meta: {
      hideTabbar: true,
      title: '我的任务',
    },
  },
  {
    name: 'integralAccount',
    path: '/integralAccount',
    component: () => import('./view/integralAccount'),
    meta: {
      hideTabbar: true,
      title: '积分账户',
    },
  },
  {
    name: 'platformExperience',
    path: '/platformExperience',
    component: () => import('./view/platformExperience'),
    meta: {
      hideTabbar: true,
      title: '平台经验',
    },
  },
  {
    name: 'commonQuestion',
    path: '/commonQuestion',
    component: () => import('./view/commonQuestion'),
    meta: {
      hideTabbar: true,
      title: '常见问题',
    },
  },
  {
    name: 'forgetPassword',
    path: '/forgetPassword',
    component: () => import('./view/forgetPassword'),
    meta: {
      hideTabbar: true,
      title: '忘记密码',
    },
  },
  {
    name: 'updatePassword',
    path: '/updatePassword',
    component: () => import('./view/setting/updatePassword'),
    meta: {
      hideTabbar: true,
      title: '修改密码',
    },
  },
  {
    name: 'personalInfo',
    path: '/personalInfo',
    component: () => import('./view/setting/personalInfo'),
    meta: {
      hideTabbar: true,
      title: '个人资料',
    },
  },
  {
    name: 'addressManage',
    path: '/addressManage',
    component: () => import('./view/setting/addressManage'),
    meta: {
      hideTabbar: true,
      title: '地址管理',
    },
  },
  {
    name: 'addAddress',
    path: '/addAddress',
    component: () => import('./view/setting/addressManage/addAddress'),
    meta: {
      hideTabbar: true,
      title: '添加地址',
    },
  },
  {
    name: 'updateAddress',
    path: '/updateAddress',
    component: () => import('./view/setting/addressManage/updateAddress'),
    meta: {
      hideTabbar: true,
      title: '修改地址',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      goBack: true,
      goHome: true,
      hideTabbar: true,
      title: '登录',
    },
  },
  {
    name: 'taskList',
    path: '/task/list',
    component: () => import('./view/task/list'),
    meta: {
      // hideTabbar: true,
      goBack: true,
      goHome: true,
      title: '任务',
    },
  },
  {
    name: 'taskDetail',
    path: '/task/detail',
    component: () => import('./view/task/detail'),
    meta: {
      hideTabbar: true,
      title: '任务详情',
    },
  },
  {
    name: 'setting',
    path: '/setting',
    component: () => import('./view/setting'),
    meta: {
      hideTabbar: true,
      title: '设置',
    },
  },
  // {
  //   name: 'notFound',
  //   path: '/:path(.*)+',
  //   redirect: {
  //     name: 'goods',
  //   },
  // },

  {
    name: 'cart',
    path: '/cart',
    component: () => import('./view/cart'),
    meta: {
      goBack: true,
      goHome: true,
      title: '购物车',
    },
  },
  {
    name: 'goodList',
    path: '/products/list',
    component: () => import('./view/products/list'),
    meta: {
      hideTabbar: true,
      title: '商品列表',
    },
  },
  {
    name: 'goodDetail',
    path: '/products/detail',
    component: () => import('./view/products/detail'),
    meta: {
      hideTabbar: true,
      title: '好物详情',
    },
  },
  {
    name: 'confirm',
    path: '/order/confirm',
    component: () => import('./view/order/confirm'),
    meta: {
      hideTabbar: true,
      title: '订单确认',
    },
  },
  {
    name: 'exchange',
    path: '/order/exchange',
    component: () => import('./view/order/exchange'),
    meta: {
      hideTabbar: true,
      title: '订单确认',
    },
  },
  {
    name: 'complate',
    path: '/order/complate',
    component: () => import('./view/order/complate'),
    meta: {
      hideTabbar: true,
      title: '支付成功',
    },
  },
  {
    name: 'mine',
    path: '/mine',
    component: () => import('./view/mine'),
    meta: {
      goBack: true,
      goHome: true,
      title: '我的',
    },
  },
  {
    name: 'orderList',
    path: '/order/list',
    component: () => import('./view/order/list'),
    meta: {
      hideTabbar: true,
      title: '订单列表',
    },
  },
  {
    name: 'orderDetail',
    path: '/order/detail',
    component: () => import('./view/order/detail'),
    meta: {
      hideTabbar: true,
      title: '订单详情',
    },
  },
  {
    name: 'serve',
    path: '/serve',
    component: () => import('./view/serve'),
    meta: {
      hideTabbar: true,
      title: '选择售后类型',
    },
  },
  {
    name: 'refund',
    path: '/serve/refund',
    component: () => import('./view/serve/refund'),
    meta: {
      hideTabbar: true,
      title: '申请退款',
    },
  },
  {
    name: 'refundGood',
    path: '/serve/refundGood',
    component: () => import('./view/serve/refundGood'),
    meta: {
      hideTabbar: true,
      title: '申请退货退款',
    },
  },
  {
    name: 'exchangeGood',
    path: '/serve/exchangeGood',
    component: () => import('./view/serve/exchangeGood'),
    meta: {
      hideTabbar: true,
      title: '申请换货',
    },
  },
  {
    name: 'refundGoodInfo',
    path: '/serve/refundGoodInfo',
    component: () => import('./view/serve/refundGoodInfo'),
    meta: {
      hideTabbar: true,
      title: '售后详情',
    },
  },
  {
    name: 'coupon',
    path: '/coupon',
    component: () => import('./view/coupon'),
    meta: {
      hideTabbar: true,
      title: '我的卡券',
    },
  },
  {
    name: 'answer',
    path: '/answer',
    component: () => import('./view/answer'),
    meta: {
      hideTabbar: true,
      title: '任务',
    },
  },
  {
    name: 'payComplate',
    path: '/order/payComplate',
    component: () => import('./view/order/payComplate'),
    meta: {
      hideTabbar: true,
      title: '支付回调',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // history: createWebHashHistory(),
});

const loginRequiredList = [
  '/cart',
  '/myTask',
  '/setting',
  '/order/list',
  '/integralAccount',
  '/coupon',
  '/reputation',
];

router.beforeEach((to, from, next) => {
  // pvAdd({ url: to.fullPath }); // 页面 pv 统计

  // historyAdd({ res_id: to.query?.guid, type: to.name }); // 页面浏览记录

  // getOpenId(to); // 获取 openid
  // 判断路由是否放行
  const { path } = to;
  if (loginRequiredList.includes(path) && !localUserInfo.token) {
    showConfirmDialog({
      title: '请先登录',
    })
      .then(() => {
        return next('/login');
      })
      .catch(() => {});
  } else {
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export { router };
